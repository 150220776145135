import React from 'react';
import { useRouter } from 'next/router';
import { useSitecoreContext } from '@liander/context';

type TrackingContextProps = ReturnType<typeof getTrackers>;
const TrackingContext = React.createContext<TrackingContextProps | null>(null);

type BaseFormOptions = { formName: string; siteSection: string; stepIndex?: number };
type SharedBaseFormOptions = Pick<BaseFormOptions, 'formName' | 'stepIndex'>;

const getBaseFormTrackingData = ({ formName, siteSection }: BaseFormOptions) => ({
  actionType: 'form',
  pageCategory: 'not available',
  siteSection,
  formName: formName ?? 'not available',
  pagePath: window.location.pathname,
  pageName: window.document.title ?? 'not available',
  applicationName: 'liander.nl',
});

export const trackFormStart =
  (siteSection: string) =>
  ({ formName, stepIndex }: SharedBaseFormOptions) => {
    window._satellite.track(`custom_track_form`, {
      ...getBaseFormTrackingData({ formName, siteSection }),
      action: `start`,
      actionDetail: `not available`,
      formStep: stepIndex ? stepIndex : `not available`,
    });
  };

export const trackSubmitSuccess =
  (siteSection: string) =>
  ({ formName, stepIndex }: SharedBaseFormOptions) => {
    window._satellite.track(`custom_track_form`, {
      ...getBaseFormTrackingData({ formName, siteSection }),
      action: `submit`,
      actionDetail: `form '${formName}': submit`,
      formStep: stepIndex ? stepIndex : `not available`,
    });
  };

type FormErrorOptions = SharedBaseFormOptions & { errorString: string };
export const trackSubmitErrors =
  (siteSection: string) =>
  ({ formName, stepIndex, errorString }: FormErrorOptions) => {
    window._satellite.track('custom_track_error', {
      ...getBaseFormTrackingData({ formName, siteSection }),
      action: 'error',
      actionDetail: `form '${formName}' validation error: ${errorString}`,
      formStep: stepIndex ? stepIndex : `not available`,
    });
  };

type FormProceed = SharedBaseFormOptions & { stepName?: string; stepIndex: number };
export const trackSubmitProceed =
  (siteSection: string) =>
  ({ formName, stepIndex }: FormProceed) => {
    window._satellite.track('custom_track_error', {
      ...getBaseFormTrackingData({ formName, siteSection }),
      action: 'proceed',
      actionDetail: `not available`,
      formStep: stepIndex ? stepIndex : `not available`,
    });
  };

export const trackPageChange = (siteSection: string) => () => {
  window._satellite.track('custom_track_page', {
    action: 'page_load',
    actionDetail: 'main',
    actionType: 'page_load',
    pageName: window.document.title,
    pagePath: window.location.pathname,
    pageCategory: 'not available',
    siteSection,
  });
};

// Note: Should this be a specific FAQ change?
type AccordionOptions = { actionDetail: string; opened: boolean };
export const trackAccordionChange = (siteSection: string) => (options: AccordionOptions) => {
  const { opened, actionDetail } = options;
  window._satellite.track('custom_track_faq', {
    action: `accordion ${opened ? 'open' : 'closed'}`,
    actionDetail,
    actionType: 'faq',
    pageCategory: 'not available',
    pagePath: window.location.pathname,
    pageName: window.document.title,
    siteSection,
  });
};

type SearchOptions = { keyphrase: string; totalItems: number; segment?: string };
export const trackSearchChange = (siteSection: string) => (options: SearchOptions) => {
  const { keyphrase, segment, totalItems } = options;
  window._satellite.track('custom_track_search', {
    action: 'search',
    actionDetail: `search term: ${keyphrase}`,
    actionType: 'search',
    pageCategory: 'not available',
    pagePath: window.location.pathname,
    siteSection,
    onsiteSearchTerm: `${keyphrase}`,
    onsiteSearchResultType: segment ? segment : `none selected`,
    onsiteSearchResultNumberofResults: `${totalItems} results`,
  });
};

type WizardSimpleOptions = {
  wizardName: string;
  action: string;
  actionDetail: string;
  wizardStep: string;
};

export const trackWizardSimpleChange = (siteSection: string) => (options: WizardSimpleOptions) => {
  window._satellite.track('custom_track_wizard', {
    actionType: 'wizard',
    pageCategory: 'not available',
    pagePath: window.location.pathname,
    siteSection,
    ...options,
  });
};

export const trackPostalcodeCheckSuccess = (siteSection: string) => () => {
  window._satellite.track(`custom_track_wizard`, {
    action: 'proceed',
    actionDetail: 'postcode check homepage',
    wizardName: 'postcode check homepage',
    actionType: 'wizard',
    pagePath: window.location.pathname,
    wizardStap: '1',
    pageName: window.document.title,
    siteSection,
  });
};

export const trackPostalcodeCheckError =
  (siteSection: string) =>
  ({ error }: { error: string }) => {
    window._satellite.track(`custom_track_error`, {
      action: 'field validation error',
      actionDetail: error,
      wizardName: 'postcode check homepage',
      actionType: 'wizard',
      pagePath: window.location.pathname,
      wizardStap: '1',
      pageName: window.document.title,
      siteSection,
    });
  };

function getTrackers(siteSection: string) {
  return {
    trackFormStart: trackFormStart(siteSection),
    trackFormSubmitSucces: trackSubmitSuccess(siteSection),
    trackFormSubmitError: trackSubmitErrors(siteSection),
    trackFormSubmitProceed: trackSubmitProceed(siteSection),
    trackPageChange: trackPageChange(siteSection),
    trackAccordionChange: trackAccordionChange(siteSection),
    trackSearchChange: trackSearchChange(siteSection),
    trackWizardSimpleChange: trackWizardSimpleChange(siteSection),
    trackPostalcodeCheckSuccess: trackPostalcodeCheckSuccess(siteSection),
    trackPostalcodeCheckError: trackPostalcodeCheckError(siteSection),
  };
}

/**
 * On error pages like 404 we do not get context from sitecore, but we still need to send that information to the tracking.
 * That's why we've created this makeshift fallback hook for that situation
 */
type SiteSection = 'Grootzakelijk' | 'Over ons' | 'Thuis en kleinzakelijk';
function useGetSiteSection() {
  const [siteSection, setSiteSection] = React.useState<SiteSection>();

  const router = useRouter();
  React.useEffect(() => {
    const handleRouteChange = (): void => {
      const { pathname } = window.location;
      let section: SiteSection = 'Thuis en kleinzakelijk';
      if (pathname.includes('/grootzakelijk')) section = 'Grootzakelijk';
      else if (pathname.includes('/over-ons')) section = 'Over ons';
      setSiteSection(section);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.query]);

  return siteSection;
}

type TrackingContextProviderProps = {
  children: React.ReactNode;
};
export const TrackingContextProvider = ({ children }: TrackingContextProviderProps) => {
  const { context } = useSitecoreContext();
  const makeshiftSegment = useGetSiteSection();
  const siteSection = context?.segment || makeshiftSegment || 'not available';
  const trackers = getTrackers(siteSection);

  return <TrackingContext.Provider value={trackers}>{children}</TrackingContext.Provider>;
};

export const useTrackingContext = () => {
  const ctx = React.useContext(TrackingContext);
  if (!ctx) {
    throw new Error('useTrackingContext must be used within a TrackingContextProvider');
  }

  return ctx;
};

type TrackingEvents =
  | 'custom_track_error'
  | 'custom_track_faq'
  | 'custom_track_wizard'
  | 'custom_track_form'
  | 'custom_track_page'
  | 'custom_track_search';

declare global {
  interface Window {
    digitalData: {
      page: {
        pageInfo: {
          pageName: string;
          pagePath: string;
          status: number;
          issueDate: string;
          version: string;
          languageCode: string;
          pageFormat: string;
        };
        category: {
          pageCategory: string;
          pageType: string;
          siteSection: string;
        };
      };
      user: {
        userInfo: {
          userId: string;
          userType: string;
          loginStatus: string;
          consentStatus: string;
          consentType: string;
          browserLanguage: string;
        };
      };
    };
    // we need this for tracking events
    _satellite: {
      track: (eventName: TrackingEvents, data: any) => void;
    };
  }
}
